import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  readonly headerId=4;
  constructor(private http: HttpClient) { }
  getAllTags = () => {
    const headers = new HttpHeaders({
      headerId: this.headerId,
    });
    return this.http.post<any>(`${environment.apiUrl}/v1/moduleTags`, null, {
      headers: headers,
    })
  }
  getAllCategories = () => {
    const headers = new HttpHeaders({
      headerId: this.headerId,
    });
    return this.http.post<any>(`${environment.apiUrl}/v1/categories`, null, {
      headers: headers,
    })
  }
  getAllModules = () => {
    const headers = new HttpHeaders({
      headerId: this.headerId,
    });
    return this.http.post<any>(`${environment.apiUrl}/v1/moduleTypes`, null, {
      headers: headers,
    })
  }
  getAllAnalyticsModules = (payload: any) => {
    return this.http.post<any>(`${environment.apiUrl}/v1/${payload.userId ? 'analyticsModules' : 'allAnalyticsModules'}`, payload)
  }
  getAllHeaders = () => this.http.post<any>(`${environment.apiUrl}/v1/moduleTags`, null)
}
