<form [formGroup]="filterForm">
    <div class="mainCard">
        <div class="pageName">
            <img src="../../../../assets/images/manageSubs/manageSubs_icon.svg" alt="manage-users-icon">
            <h1>Manage Users</h1>
        </div>
        <div class="subsDetails dontShow" *ngIf="isSubscriptionAdmin">
            <p class="label">
                Customer
            </p>
            <p class="value">
                {{companyName}}
            </p>
        </div>
        <div class="searchANDcta">
            <input type="text" name="search" id="search" class="searchBox" placeholder="Search"
                formControlName="searchtext" (keyup.enter)="filterTableData()" *ngIf="isAdminUser">
            <button type="button" type="button" class="primaryCTA" *ngIf="isSubscriptionAdmin"
                (click)="openCreateUserPage()">Add New</button>
        </div>                                
        <div class="filtersGroup" *ngIf="isAdminUser">
            <div class="sectionName">
                <img src="../../../../assets/images/Filters.svg" alt="filters-icon">
                <p>Filters</p>
            </div>
            <div class="formGrp" *ngIf="!isSubscriptionAdmin">
                <label for="customerName">Customer</label>
                <input type="text" matInput class="searchBox" formControlName="customerId" [matAutocomplete]="auto"
                    placeholder="Type here">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                    (optionSelected)="onSelectCustomer()">
                    <mat-option [value]="option"
                        *ngFor="let option of allCustomers ">{{option.companyName}}</mat-option>
                </mat-autocomplete>
            </div>
            <div class="formGrp" *ngIf="!isSubscriptionAdmin">
                <label for="customerName">User Type</label>
                <div class="userType">
                    <ng-multiselect-dropdown [data]="allUserTypes" [placeholder]="'User Type'"
                        [settings]="usetypeFilterDropdownSettings" [(ngModel)]="selectedUserType"
                        formControlName="userType" (onSelect)="onUserTypeSelect($event)"
                        (onDeSelect)="onUserTypeDeselect($event)" (onSelectAll)="onSelectAllUserType($event)"
                        (onDeSelectAll)="onDeSelectAllUserType()">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="formGrp">
                <label for="customerName">Last Updated</label>
                <mat-form-field appearance="outline" class="datepicker">
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="DD MM YYYY" formControlName="lastModifiedStart"
                            name="startDate"  readonly/>
                        <input matEndDate placeholder="DD MM YYYY" formControlName="lastModifiedEnd" name="endDate"
                            (dateChange)="lastUpdatedDateChange()" readonly />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
            <div class="formGrp">
                <label for="customerName">Status</label>
                <div class="statusBox">
                    <ng-multiselect-dropdown [data]="allStatuses" [placeholder]="'Select Status'"
                        [settings]="statusFilterDropdownSettings" formControlName="status"
                        (onSelect)="onStatusSelect($event)" (onDeSelect)="onStatusDeselect($event)"
                        (onSelectAll)="onSelectAllStatus($event)" (onDeSelectAll)="onDeSelectAllStatus()">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div *ngIf="isSubscriptionAdmin"></div>
            <div *ngIf="isSubscriptionAdmin"></div>
            <button type="button" class="resetbtn" (click)="resetFilterForm()">
                <img src="../../../../assets/images/reset_icon.svg" alt="reset-icon">
                Reset
            </button>
        </div>
        <div class="tableCont">
            <p class="tableRowCount">
                Showing
                {{totalUsers>0? (p - 1) * itemsPerPage + 1 :0}} -
                {{ p * itemsPerPage < totalUsers ? p * itemsPerPage : totalUsers }} of {{ totalUsers }} results </p>
                    <div class="tableGroup">
                        <div class="tHead">
                            <div class="tH">
                                User
                            </div>
                            <div class="tH" *ngIf="!isSubscriptionAdmin">
                                Customer
                            </div>
                            <div class="tH" *ngIf="!isSubscriptionAdmin">
                                Type
                            </div>
                            <div *ngIf="isSubscriptionAdmin"></div>
                            <div *ngIf="isSubscriptionAdmin"></div> 
                            <div class="tH">
                                Email
                            </div>
                            <div class="tH">
                                Last Modified
                                <img (click)="lastUpdatedDateSorting()" *ngIf="!dateSort"
                                    src="../../../../assets/images/chevron-down.svg" alt="">
                                <img (click)="lastUpdatedDateSorting()" *ngIf="dateSort"
                                    src="../../../../assets/images/chevron-up.svg" alt="">

                            </div>
                            <div class="tH">
                                Status
                            </div>
                            <div class="tH">
                                Actions
                            </div>
                        </div>
                        <div class="tBody">
                            <div class="tRow" *ngFor="let user of allUsers | paginate
                    : {
                        id: 'listing_pagination',
                        itemsPerPage: 10,
                        currentPage: p,
                        totalItems: totalUsers
                      };let i=index">
                                <div class="tD">
                                    {{user.userName}}
                                </div>
                                <div class="tD" *ngIf="!isSubscriptionAdmin">
                                    {{user.customer}}
                                </div>                               

                                <div class="tD" *ngIf="!isSubscriptionAdmin">
                                    {{user.userType}}
                                </div>
                                <div *ngIf="isSubscriptionAdmin"></div>
                                <div *ngIf="isSubscriptionAdmin"></div>

                                <div class="tD">
                                    {{user.email}}
                                </div>
                                <div class="tD">
                                    {{user.lastModified|date:'d MMM yyyy'}}
                                </div>
                                <div class="tD">
                                    <div [ngClass]="checkStatusClass(user)">
                                        {{user.status}}
                                    </div>
                                </div>
                                <div class="tD dropdown-container">
                                    <label class="kebabMenuLabel" for="kebabMenu1" (click)="toggleKababMenu(i)"
                                        >
                                        <img src="../../../../assets/images/manageSubs/kebab_default.svg"
                                            alt="kebab-menu-icon">
                                    </label>
                                    <div class="actionsBox" *ngIf="i == selectedRowId && kababMenuFlag" tabindex="0" (blur)="hideMenu()">
                                        <ul>
                                            <li>
                                                <button type="button" class="kebabMenuItem" type="button"
                                                    [disabled]="checkViewEditStatus(user)"
                                                    (click)="editUser(user)"
                                                    >
                                                    <img src="../../../../assets/images/Edit.svg" alt="">
                                                    View/Edit User
                                                </button>
                                            </li>
                                            <li *ngIf="toggleActivateDeactivate(user)">
                                                <button type="button" class="kebabMenuItem" type="button"
                                                    (click)="activateDeactivateUser(user,i)"
                                                    [disabled]="checkActivateDeactivateStatus(user)">
                                                    <img src="../../../../assets/images/check-circle.svg" alt="">
                                                    {{checkActivateDeactivateText(user)}}
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" class="kebabMenuItem" type="button"
                                                    [disabled]="checkDeleteStatus(user)" (click)="deleteUser(user)">
                                                    <img src="../../../../assets/images/manageSubs/delete.svg" alt="">
                                                    Delete
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="errorFlag">
                            <div class="errorBlock">
                                <img src="../../../../assets/images/artboard-3.png" alt="artboard_icon" />
                                <p>{{ fillErrorText | titlecase }}</p>
                            </div>
                        </ng-container>
                        <div class="pagination">
                            <pagination-controls id="listing_pagination" (pageChange)="getPage($event)"
                                previousLabel="Prev" nextLabel="Next">
                            </pagination-controls>
                        </div>
                    </div>
        </div>
    </div>
</form>

<app-success-toast [message]="responseResult" *ngIf="responseResult"></app-success-toast>

<app-error-toast [message]="errMsgText" *ngIf="errMsgText && flagError"></app-error-toast>