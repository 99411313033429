import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataRequestManagerService {

  itemsPerPage = 10;
  p: number = 1;
  headerSubject:BehaviorSubject<any>=new BehaviorSubject<any>(false)
  constructor(private http: HttpClient) {}

  statusTilesWithCount(userId: string) {
    let headers = new HttpHeaders().append('userId', userId.toString());
    const requestOptions = { headers: headers };

    return this.http.post(
      `${environment.apiUrl}/v1/requestStatusCount`,
      null,
      requestOptions
    );
  }

  onSearchFilter(searchValue: any, userId: string) {
    let headers = new HttpHeaders().append('userId', userId.toString());
    const requestOptions = { headers: headers };

    return this.http.post(
      `${environment.apiUrl}/v1/requestDetailsBySearch?limit=${this.itemsPerPage}&page=${this.p}&searchText=${searchValue}`,
      null,
      requestOptions
    );
  }

  onTypeFilter(userId: string) {
    let headers = new HttpHeaders().append('userId', userId.toString());
    const requestOptions = { headers: headers };

    return this.http.post(
      `${environment.apiUrl}/v1/modules`,
      null,
      requestOptions
    );
  }

  onStatusFilter(userId: string) {
    let headers = new HttpHeaders().append('userId', userId.toString());
    const requestOptions = { headers: headers };

    return this.http.post(
      `${environment.apiUrl}/v1/statuses`,
      null,
      requestOptions
    );
  }

  onRequestDetailsFilter(filterValues: any) {
    return this.http.post(
      `${environment.apiUrl}/v1/requestDetailsFilter?limit=${this.itemsPerPage}&page=${this.p}`,
      filterValues
    );
  }

  onDownloadCall(downloadRequestBody: any, userId: string): Observable<Blob> {
    const requestOptions: Object = {
      userId: userId.toString(),
      'Content-Type': 'application/octet-stream',
    };

    return this.http.post<any>(
      `${environment.apiUrl}/v1/downloadLink`,
      downloadRequestBody,
      requestOptions
    );
  }

  onDeleteRequestCall(userId: any, requestId: string) {
    return this.http.post<any>(`${environment.apiUrl}/v1/deleteRequest`, {
      userId,
      requestId,
    });
  }

  reRunRequest(requestId: any) {
    const authId = localStorage.getItem('id');
    return this.http.post<any>(`${environment.apiUrl}/v1/rerunRequest`, {
      authId,
      requestId
    });
  }
}
