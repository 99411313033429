import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilService } from './helper/util.service';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private utilService: UtilService, private auth: AuthService,private router:Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem('token');
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const isApiUrl2 = request.url.startsWith(environment.apiUrl2);
    this.utilService.SharingDataTextMessage.next('');
    if ((accessToken && isApiUrl) || isApiUrl2) {
      if(
        request.url?.split('/')[6]!='downloadImages' && 
        request.url?.split('/')[6]!='allHeaders' && 
        request.url?.split('/')[6]!='sendEmailVerificationCode' &&
        request.url?.split('/')[6]!='validateVerificationCode'
      ){
        request = request.clone({
          setHeaders: { Authorization: `Bearer ${accessToken}` },
        });
      }
    } else if (localStorage.getItem('id') == 'undefined') {
      this.auth.isAuthenticated$.subscribe((res) => {
        if (
          res &&
          accessToken == null &&
          localStorage.getItem('userId') == null
        ) {
          window.location.reload();
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        let errorMessage: any = '';

        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
          } else {
            switch (error.status) {
              case 400:
                errorMessage = '';
                errorMessage = error.error.result.error;
                this.utilService.SharingDataTextMessage.next(errorMessage);
                this.utilService.openPopup(false);
                this.utilService.openErrorTextBlock(true);
                break;
              case 401:
                if ([401].indexOf(error.status) !== -1) {
                  errorMessage =
                    'Session Expired, Please click ok Button to re-login';
                  this.utilService.SharingDataPopUpMessage.next(errorMessage);
                  this.utilService.openErrorTextBlock(true);
                  this.utilService.openPopup(true);
                }
                break;

              case 404:
                errorMessage = '';
                errorMessage = error.error.result.error;
                this.utilService.SharingDataTextMessage.next(errorMessage);
                this.utilService.openPopup(false);
                this.utilService.openErrorTextBlock(true);
                break;
              case 500:
                errorMessage = '';
                errorMessage = error.error.result.error;
                this.utilService.SharingDataTextMessage.next(errorMessage);
                this.utilService.openPopup(false);
                this.utilService.openErrorTextBlock(true);
                break;
            }
          }
        } else {
          console.log('An Error Occured!');
        }

        if (
          error.error.code === 401 ||
          error.error.error === 'InvalidUserCredentialsException'
        ) {
          errorMessage = error.error.result.error;
          this.utilService.SharingDataTextMessage.next(errorMessage);
          this.utilService.openPopup(false);
          this.utilService.openErrorTextBlock(true);
        }
        return throwError(() => new Error(error.statusText));
      })
    );
  }
}
