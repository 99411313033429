import { Component, HostListener } from '@angular/core';
import { CreateUserService } from '../create-user/create-user.service';
import { UtilService } from 'src/app/shared/helper/util.service';
import { HomePageService } from 'src/app/shared/services/homepage.service';
import { ManageSubscriptionService } from '../../subscription/manage-subscription.service';
import { CreateSubscriptionService } from '../../subscription/edit-subscription/create-subscription.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { emailAsyncValidator } from 'src/app/core/Validators/email-async.validator';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { BehaviorSubject, catchError, concatMap, from, map, Observable, of, pluck, Subject, tap, toArray } from 'rxjs';
import { emailAsyncEditValidator } from 'src/app/core/Validators/email-async-edit.validator';
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss', './modal.scss']
})
export class EditUserComponent {
  errorIcon = '../assets/images/users/warning-icon.svg'
  greenChevronSouth = '../assets/images/users/green-chevron-south.svg'
  editIconBtn = '../assets/images/users/edit-icon.svg'
  closeIconBtn = '../assets/images/users/closeIcon-16x16.svg'
  deleteIconBtn = '../assets/images/users/trash-icon.svg'
  removeIconBtn = '../assets/images/users/closeIcon-16x16.svg'
  userId: any = null;
  userDetails: any = null;
  userForm: FormGroup;
  subscriptionList: any = []
  allActiveSubscriptions: any = []
  moduleList: any = null
  toggleSubscriptions = false;
  toggleModules = false;
  allModules: any = []
  mode: any = []
  datamode: any = []
  modules: any = []
  moduleDropdownActiveId: any = null
  modulePopupFlag = false;
  chips: any = [];
  searchText: any
  activeTabUserGroupsCopy: any = []
  selectedModule: any = null;
  groupLimit = 4
  showOtherSelectedGroupsFlag = false;
  activeSubscriptionIndex: any = null;
  totalGroupAssignedCount = 0
  startIndex = 0;
  itemsPerSlide = 3;
  currentEmail$ = new BehaviorSubject<any>('');
  activeTabModules: any = [
    { id: 1, userGroups: [], selected: true, moduleName: 'test 1' },
    { id: 2, userGroups: [], selected: false, moduleName: 'test 2' },
    { id: 3, userGroups: [], selected: false, moduleName: 'test 3' },
  ]
  activeTabUserGroups: any = []
  checkedGroupsArray: any = [];
  selectedModuleTabId = 0
  subscriptionFilterDropdownSettings: IDropdownSettings = {};
  selectedSubscription: any = []
  modulesWithGroups: any = []
  userType: any = null;
  isSubscriptionAdmin = false
  responseResult: string = null;
  errMsgText: string = null;
  flagError = false;
  isEdit = false;
  editUserId: any = null;
  activeTabUserGroupsArray: any = [];
  editUserDetails: any = null;
  userSubscriptionDetails: any = null;
  activeSlideIndex: number = 0;
  constructor(
    private createUserService: CreateUserService,
    private utilService: UtilService,
    private homePageService: HomePageService,
    private manageSubscriptionService: ManageSubscriptionService,
    private createSubscriptionService: CreateSubscriptionService,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute

  ) {

  }
  hasExecuted = false;

  limitMethod() {
    if (this.hasExecuted) {
      return;
    }
    if (this.allActiveSubscriptions.length == 1) {
      console.log('test')
      this.addSubscription(this.allActiveSubscriptions[0])
    }
    this.hasExecuted = true;
  }
  get firstname() {
    return this.userForm.get('firstname') as FormControl;
  }
  get lastname() {
    return this.userForm.get('lastname') as FormControl;
  }
  get email() {
    return this.userForm.get('email') as FormControl;
  }
  get subscription() {
    return this.userForm.get('subscription') as FormArray;
  }
  ngOnInit() {
    this.initForm();
    this.getEditSubscriptionandModules()
    this.getUserDetails()
  }
  getSubscriptionAndUserGroups() {
    this.utilService.getUserId.subscribe((id: any) => {
      this.userId = id as string;
      this.userId && this.getAllSubscriptions();
      this.userId && this.getUserGroups()
      if (localStorage.getItem('userId')) {
        this.userType = localStorage.getItem('userType');
        if (this.userType == 'Subscription Admin') {
          this.isSubscriptionAdmin = true;
        } else {
          this.isSubscriptionAdmin = false;
        }
      }
    });
  }

  getEditSubscriptionandModules() {
    this.activatedRoute.paramMap.subscribe((res: any) => {
      let { id } = res?.params;
      if (id) {
        this.editUserId = id;
        this.isEdit = true;
        let payload = {
          "userId": this.editUserId,
          "loggedInUserId": localStorage.getItem('userId')
        }
        this.createUserService.getSubscriptionWithModules(payload).pipe(
          map((subscriptions: any) => ({
            "userId": subscriptions.result.userId,
            "firstName": subscriptions.result.firstName,
            "lastName": subscriptions.result.lastName,
            "email": subscriptions.result.email,
            "company": subscriptions.result.company,
            "userType": subscriptions.result.userType,
            subscriptionModules: subscriptions.result.subscriptionModules
          })),
          tap((res: any) => { this.editUserDetails = res; this.currentEmail$.next(res.email); this.getSubscriptionAndUserGroups() }),
          concatMap((subscriptions: any) => {
            return from(subscriptions.subscriptionModules).pipe(
              concatMap((susbcription: any) =>
                this.createSubscriptionService.loadSubscriptionDetails(susbcription?.subscriptionId, susbcription.userId).pipe(
                  map((modules: any) => ({
                    ...susbcription,
                    modules: modules?.result?.subscriptionModules?.filter((item1: any) => !susbcription?.assignedModules?.some((item2: any) => item1?.id == item2?.moduleId))
                  })),
                )
              )
            );
          }),
          toArray(),
          catchError((error: any) => {
            console.log(error);
            // console.error('Error fetching data:', error);
            throw error;
          })
        ).subscribe(
          {
            next: (res: any) => {
              if (res) {
                console.log('demo:',res)
                this.userSubscriptionDetails = res;
                console.log(this.editUserDetails)
                if (this.userSubscriptionDetails) {
                  this.userForm.setValue({
                    firstname: this.editUserDetails?.firstName,
                    lastname: this.editUserDetails?.lastName,
                    email: this.editUserDetails?.email,
                    cutomer: this.editUserDetails?.company,
                    userType: 'User',
                    subscription: [],
                  })

                  this.userSubscriptionDetails.forEach((item: any) => {
                    // console.log('moduleList', item.modules.result.subscriptionModules)
                    let modules = item?.assignedModules?.map((item: any) => ({
                      id: item?.moduleId,
                      moduleName: item?.moduleName,
                      isAssigned: true,
                      userGroups: item?.assignedUserGroups.map((ele: any) => ({
                        "id": ele?.groupId,
                        "userGroupName": ele?.groupName,
                        "userGroupDescription": "",
                        "isAssigned": true
                      })),
                    }))
                    console.log('modules', modules)
                    let subscription: any = this.fb.group({
                      "contactName": [''],
                      "customerName": [this.editUserDetails?.company],
                      "endsOn": [item?.endDate],
                      "startsOn": [item?.startDate],
                      "statusName": [item?.status],
                      "subscriptionId": [item?.subscriptionId],
                      "subscriptionName": [item?.subscriptionName],
                      "moduleList": this.fb.array([...item.modules]),
                      "modules": this.fb.array([...modules]),
                      "selectedModule": [null],
                      "checkedUserGroups": this.fb.array([]),
                      "isAssigned": [true],
                      "subscriptionUserId":[item.userId]
                    })

                    this.subscription.push(subscription)
                  })
                }
              }
            },
            error: (error) => {
              this.spinnerService.hide()
              console.log("this is error",error)
              this.utilService.SharingDataTextMessage.subscribe((res: any) => {
                this.errMsgText = res
              })
              this.flagError = true;
              setTimeout(() => { this.flagError = false; this.errMsgText = '' }, 5000)
            }
          }

        )
        this.userForm.markAsTouched()
        this.userForm.markAsDirty()
      } else {
        this.isEdit = false;
        this.editUserId = null;
      }
    })


  }
  initForm() {
    this.userForm = this.fb.group({
      firstname: [
        ''
        ,
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z ]*$'),
          Validators.minLength(2),
          Validators.maxLength(50),
        ]
      ],
      lastname: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z ]*$'),
        Validators.minLength(2),
        Validators.maxLength(50),
      ]
      ],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$')], [emailAsyncEditValidator(this.createUserService, this.currentEmail$)]],
      cutomer: [''],
      userType: ['User'],
      subscription: this.fb.array([], [Validators.required])
    })
  }
  submitUserForm() {}
  getAllSubscriptions = () => {
    this.manageSubscriptionService.fetchAllSubscriptionRecord(this.userId).subscribe((res: any) => {
      if (res) {
        const allSubscriptions = _.cloneDeep(res?.result)
        this.allActiveSubscriptions = allSubscriptions.filter((element: any) => {
          return element['statusName'] != 'DELETED' && element['statusName'] != 'INACTIVE'
        });
        this.subscriptionList = _.cloneDeep(res?.result.filter((item1: any) => !this.editUserDetails.subscriptionModules?.some((item2: any) => item1?.subscriptionId == item2?.subscriptionId)))
        this.sortSubscriptionList()
        this.limitMethod()
      }
    })
  }
  getUserDetails = () => {
    this.homePageService.getUserByAuthId().subscribe((res: any) => {
      if (res?.result) {
        this.userDetails = res?.result
        this.userForm.get('cutomer').setValue(this.userDetails?.companyName);
        this.userForm.get('userType').setValue('User');

      }
    })
  }
  getSubscriptionDetails = (subscriptionId: any) => {
    this.spinnerService.show();
    return new Promise((resolve, reject) => {
      this.createSubscriptionService.loadSubscriptionDetails(subscriptionId, this.userId).subscribe({
        next: (res: any) => { if (res) { this.moduleList = res?.result?.subscriptionModules?.filter((item: any) => item.isModuleAttached == true); this.spinnerService.hide(); return resolve('completed'); } },
        // error: (err) => { this.spinnerService.hide() }
        error: (err) => {
          this.spinnerService.hide()
          this.utilService.SharingDataTextMessage.subscribe((res: any) => {
            this.errMsgText = res
          })
          this.flagError = true;
          setTimeout(() => { this.flagError = false; this.errMsgText = '' }, 5000)
        }
      })
    })
  }
  addSubscription(subscription: any) {
    console.log('test2')
    this.toggleSubscriptions = false;
    this.resetDropdown()
    this.getSubscriptionDetails(subscription.subscriptionId).then((res: any) => {
      if (res == 'completed') {
        this.subscription.push(this.fb.group({
          contactName: [subscription.contactName],
          customerName: [subscription.customerName],
          endsOn: [subscription.endsOn],
          startsOn: [subscription.startsOn],
          statusName: [subscription.statusName],
          subscriptionId: [subscription.subscriptionId],
          subscriptionName: [subscription.subscriptionName],
          moduleList: this.fb.array(this.moduleList),
          modules: this.fb.array([]),
          selectedModule: [null],
          checkedUserGroups: this.fb.array([])
        }))
      }
      this.allActiveSubscriptions = this.allActiveSubscriptions?.filter((item: any) => item.subscriptionId != subscription.subscriptionId)
      this.allActiveSubscriptions = _.cloneDeep(this.allActiveSubscriptions.sort((a: any, b: any) => b.subscriptionId - a.subscriptionId))
    })

  }
  openDialog() {
    this.dialog.open(DialogComponent, {
      width: '250px',
    });
  }
  deleteSubscription(index: any, subscriptionItem: any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Confirmation',
        message: 'Would you like to delete record?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.subscription.removeAt(index);
        this.allActiveSubscriptions.push(subscriptionItem.value)
        this.allActiveSubscriptions = _.cloneDeep(this.allActiveSubscriptions.sort((a: any, b: any) => b.subscriptionId - a.subscriptionId))
        // this.sortSubscriptionList()
        this.resetDropdown()
        this.activeTabUserGroups = [];
        this.checkedGroupsArray = [];
        this.createUserService.activeUserGroup.next([])
        this.toggleSubscriptions = false
      } else {
        console.log('Delete action canceled');
      }
    });

  }
  sortSubscriptionList() {
    this.allActiveSubscriptions = _.cloneDeep(this.subscriptionList.sort((a: any, b: any) => b.subscriptionId - a.subscriptionId))
  }
  showModuleDropdown(subscription: any) {
    this.toggleModules = !this.toggleModules;
    this.moduleDropdownActiveId = this.toggleModules ? subscription.value.subscriptionId : null;
  }
  addModules(module: any, i: any, idx: any) {
    console.log(module)
    module.isAssigned = true;
    this.subscription.value[i].modules.push(module);
    this.subscription.value[i].checkedUserGroups.push(module);
    this.subscription.value[i].moduleList.splice(idx, 1);
    console.log(this.subscription.value[i].modules)
    this.chips = _.cloneDeep(this.subscription?.value[i]?.modules)
    this.activateTabs(0)
    this.moduleDropdownActiveId = null
  }
  showUserGroup() { }
  showUGPopup(i: any) {
    this.modulePopupFlag = true
    this.activeSubscriptionIndex = i;
    this.chips = _.cloneDeep(this.subscription?.value[this.activeSubscriptionIndex]?.modules);
    this.switchTab(this.subscription.value[this.activeSubscriptionIndex]?.selectedModule?.id)
    let counter = 0
    this.subscription.value[this.activeSubscriptionIndex].modules.forEach((item: any) => {
      if (item.userGroups) {
        counter += item.userGroups.length;
      }
    })
    this.totalGroupAssignedCount = counter;
    this.createActiveModulesWithGroup()
    let activetab = this.chips.findIndex((item: any) => item.selected == true);
    this.activeSlideIndex = Math.floor(((+activetab) / this.itemsPerSlide));
  }
  closeUGPopup() {
    this.modulePopupFlag = false;
  }
  get visibleChips() {
    this.startIndex = this.activeSlideIndex * 3
    return this.chips.slice(this.startIndex, this.startIndex + this.itemsPerSlide);
  }
  next() {
    if (this.startIndex + this.itemsPerSlide < this.chips.length) {
      this.startIndex += this.itemsPerSlide;
      this.updateActiveSlide();
    }
  }
  prev() {
    if (this.startIndex > 0) {
      this.startIndex -= this.itemsPerSlide;
      this.updateActiveSlide();
    }
  }
  updateActiveSlide() {
    this.activeSlideIndex = Math.floor(this.startIndex / this.itemsPerSlide);
    console.log(this.activeSlideIndex)
  }
  removeAllSelectedGropus() {
    this.activeTabUserGroups.forEach((item: any) => {
      item.isAssigned = false;
    })
    this.checkedGroupsArray = this.activeTabUserGroups.filter((item: any) => item.isAssigned == true)
    this.setActiveTabUserGroupsArray()
  }
  showOtherSelectedGroups() {
    this.showOtherSelectedGroupsFlag = !this.showOtherSelectedGroupsFlag;
  }
  switchTab(id: any, moduleItem: any = null) {
    if (moduleItem == null) {
      let module = this.subscription.value[this.activeSubscriptionIndex].modules?.find((item: any) => item.id == id);
      if (module) {
        this.subscription.value[this.activeSubscriptionIndex].selectedModule = module
      }
    } else {
      this.subscription.value[this.activeSubscriptionIndex].selectedModule = moduleItem;
    }
    this.showOtherSelectedGroupsFlag = false
    let index = this.chips.findIndex((item: any) => item.id == id);
    let index2 = this.modulesWithGroups.findIndex((item: any) => item.id == id);
    this.activeTabUserGroups = _.cloneDeep(this.modulesWithGroups[index2]?.userGroups);
    let selectedModule = this.subscription?.value[this.activeSubscriptionIndex]?.selectedModule;
    let indx = this.subscription.value[this.activeSubscriptionIndex]?.modules?.findIndex((item: any) => item.id == selectedModule.id)
    this.activeTabUserGroups?.forEach((item: any) => {
      let idx = this.subscription.value[this.activeSubscriptionIndex]?.modules[indx]?.userGroups?.findIndex((ele: any) => ele?.id == item?.id)
      if (idx > -1) {
        item.isAssigned = true;
      }
    })
    this.checkedGroupsArray = _.cloneDeep(this.activeTabUserGroups?.filter((item: any) => item.isAssigned == true))
    this.createUserService.activeUserGroup.next(this.activeTabUserGroups)
    if (index > -1) { this.activateTabs(index) }
  }
  activateTabs(index = 0) {
    this.chips.forEach((item: any, i: any) => this.chips[i].selected = (i == index));
  }
  removeModule(moduleItem: any, i: any, indx: any) {
    this.subscription?.value[i]?.modules?.splice(indx, 1)
    this.subscription?.value[i]?.checkedUserGroups?.splice(indx, 1)
    if (this.subscription?.value[i]?.selectedModule && this.subscription?.value[i]?.selectedModule.id == moduleItem.id) {
      this.subscription.value[i].selectedModule = null
    }
    if (moduleItem.userGroups) { moduleItem.userGroups = [] }
    this.subscription?.value[i]?.moduleList?.push(moduleItem)
    this.chips = _.cloneDeep(this.subscription?.value[i]?.modules)
  }
  resetDropdown() {
    this.moduleDropdownActiveId = null;
  }
  clearText() {
    this.searchText = "";
    this.activeTabUserGroups = _.cloneDeep(this.activeTabUserGroupsCopy)
  }
  checkSearchIcon() {
    return this.searchText?.length > 0
  }
  onChangeGroup(group: any) {
    group.isAssigned = !group.isAssigned;
    this.checkedGroupsArray = this.activeTabUserGroups.filter((item: any) => item.isAssigned == true)
    // this.createActiveModulesWithGroup()
    this.setActiveTabUserGroupsArray()
  }
  setActiveTabUserGroupsArray() {
    let selectedModule = this.subscription?.value[this.activeSubscriptionIndex]?.selectedModule;
    let index = this.activeTabUserGroupsArray.findIndex((item: any) => item.id == selectedModule.id)
    this.activeTabUserGroupsArray[index].userGroups = _.cloneDeep(this.checkedGroupsArray)
  }
  getUserGroups() {
    this.createUserService.fetchModulesAndGroups(this.userId).subscribe(
      {
        next: (res: any) => {
          let capabilities = res.result.find((item: any) => item.headerLabel == 'Capabilities')
          this.modulesWithGroups = capabilities.modules
        },
        error: (error) => {
          this.spinnerService.hide()
          this.utilService.SharingDataTextMessage.subscribe((res: any) => {
            this.errMsgText = res
          })
          this.flagError = true;
          setTimeout(() => { this.flagError = false; this.errMsgText = '' }, 5000)
        }
      }

    )
  }
  createActiveModulesWithGroup() {
    this.activeTabUserGroupsArray = [];
    let selectedModule = this.subscription?.value[this.activeSubscriptionIndex]?.selectedModule;
    let index = this.subscription.value[this.activeSubscriptionIndex]?.modules?.findIndex((item: any) => item.id == selectedModule.id)
    let modules = _.cloneDeep(this.modulesWithGroups.filter((item: any) => this.subscription.value[this.activeSubscriptionIndex]?.modules.some((obj: any) => obj.id === item.id)))

    modules.forEach((ele: any) => {
      let moduleIndex = this.subscription.value[this.activeSubscriptionIndex]?.modules?.findIndex((item: any) => item.id == ele.id)
      if (moduleIndex > -1) {
        ele.userGroups = _.cloneDeep(this.subscription.value[this.activeSubscriptionIndex]?.modules[moduleIndex].userGroups)
      }
    })
    this.activeTabUserGroupsArray = modules;
  }
  selectModule(moduleItem: any, i: any, indx: any) {
    this.activeSubscriptionIndex = i;
    this.subscription.value[i].selectedModule = moduleItem;
    this.switchTab(moduleItem.id, moduleItem)
    let modules = _.cloneDeep(this.modulesWithGroups.find((item: any) => item.id == moduleItem.id))
    if (modules) {
      this.activeTabUserGroups = _.cloneDeep(modules.userGroups)
    } else {
      this.activeTabUserGroups = []
    }
  }
  filterUserGroups() {
    if (this.searchText != "") {
      let groups = _.cloneDeep(this.activeTabUserGroupsCopy)
      this.activeTabUserGroups = groups.filter((item: any) => item.userGroupName?.toLowerCase()?.includes(this.searchText.toLowerCase()))
    } else {
      this.activeTabUserGroups = _.cloneDeep(this.activeTabUserGroupsCopy)
    }
  }
  ngAfterViewInit() {
    this.createUserService.activeUserGroup.subscribe((res) => {
      this.activeTabUserGroupsCopy = res
    })
  }
  uncheckGroup(groupId: any) {
    let index = this.activeTabUserGroups.findIndex((item: any) => item.id == groupId);
    if (index > -1) {
      this.activeTabUserGroups[index].isAssigned = false
    }
    this.checkedGroupsArray = this.activeTabUserGroups.filter((item: any) => item.isAssigned == true)
    this.setActiveTabUserGroupsArray()
  }
  closePopup() {
    this.modulePopupFlag = false
    this.activeTabModules = []
    this.activeTabUserGroups = []
    this.createUserService.activeUserGroup.next([])
    this.searchText = "";
  }
  assignGroups() {
    console.log(this.checkedGroupsArray)
    console.log(this.subscription)
    let selectedModule = this.subscription?.value[this.activeSubscriptionIndex]?.selectedModule;
    let index = this.subscription.value[this.activeSubscriptionIndex]?.modules?.findIndex((item: any) => item.id == selectedModule.id)
    this.subscription.value[this.activeSubscriptionIndex].modules[index].userGroups = [...this.checkedGroupsArray]
    console.log(this.subscription.value[this.activeSubscriptionIndex])
    this.subscription.value[this.activeSubscriptionIndex]?.modules.forEach((ele: any) => {
      let moduleIndx = this.activeTabUserGroupsArray.findIndex((item: any) => item.id == ele.id);
      if (moduleIndx > -1) {
        ele.userGroups = _.cloneDeep(this.activeTabUserGroupsArray[moduleIndx]?.userGroups)
      }
    })
    this.closePopup();
  }
  checkModules() {
    let flag = false;
    this.subscription.value.forEach((element: any) => {
      if (element?.modules?.length == 0) {
        flag = true
      }
    })
    return flag;
  }
  checkUserGroup() {
    let flag = false;
    this.subscription.value.forEach((element: any) => {
      element.modules.forEach((ele: any) => {
        if (ele?.userGroups?.length == 0 || ele?.userGroups == null) {
          flag = true
        }
      })
    })
    return flag;
  }
  saveUser() {
    let payload = {
      "loggedInUserId": this.userId,
      "userId": this.editUserDetails.userId,
      "firstName": this.userForm.value?.firstname,
      "lastName": this.userForm.value?.lastname,
      "email": this.userForm.value?.email,
      "company": this.userForm.value?.cutomer,
      "userType": 'User',
      "subscriptionModules": this.getSubscriptions()
    }
    this.createUserService.editUser(payload).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.responseResult = res.result;
          setTimeout(() => {
            this.responseResult = '';
            this.router.navigate(['/manage-users'])
          }, 5000)
        }
      },
      error: (err) => {
        this.spinnerService.hide()
        this.utilService.SharingDataTextMessage.subscribe((res: any) => {
          this.errMsgText = res
        })
        this.flagError = true;
        setTimeout(() => { this.flagError = false; this.errMsgText = '' }, 5000)
      }
    })
  }
  getSubscriptions() {
    let subscriptions: any = [];
    let susbcriptions = _.cloneDeep(this.editUserDetails?.subscriptionModules)
    susbcriptions?.forEach((item: any) => {
      let index = this.subscription?.value.findIndex((ele: any) => ele.subscriptionId == item.subscriptionId);
      if (index > -1) {
        item?.assignedModules.forEach((item1: any, idx: any) => {
          let moduleIdx = this.subscription?.value[index]?.modules?.findIndex((itemModule: any) => itemModule.id == item1.moduleId)
          if (moduleIdx > -1) {
            let deletedGroups = item1?.assignedUserGroups?.filter((itemgroup1: any) => !this.subscription?.value[index]?.modules[moduleIdx].userGroups?.some((itemgroup2: any) => itemgroup1?.groupId == itemgroup2?.id));
            console.log('deletedGroups', deletedGroups);
            if (deletedGroups.length > 0) {
              deletedGroups.forEach((item3: any) => {
                item3.isAssigned = false;
                this.subscription?.value[index]?.modules[moduleIdx]?.userGroups?.push({
                  id: item3.groupId,
                  isAssigned: item3.isAssigned,
                  userGroupDescription: '',
                  userGroupName: item3.groupName,
                })
              })

            }

          }

        });

      }
    })

    susbcriptions?.forEach((item: any) => {
      let index = this.subscription?.value.findIndex((ele: any) => ele.subscriptionId == item.subscriptionId);
      if (index > -1) {
        let deletedModules = item?.assignedModules?.filter((item1: any) => !this.subscription?.value[index]?.modules?.some((item2: any) => item1?.moduleId == item2?.id));
        if (deletedModules?.length > 0) {
          deletedModules?.forEach((element: any) => {
            let idx = item?.assignedModules?.findIndex((module: any) => module?.moduleId == element?.moduleId);
            if (idx > -1) {
              item.assignedModules[idx].isAssigned = false;
              item?.assignedModules[idx]?.assignedUserGroups?.forEach((ele1: any) => {
                ele1.isAssigned = false;
              })
            }
            this.subscription?.value[index]?.modules?.push({
              id: element.moduleId,
              isAssigned: element?.isAssigned,
              moduleName: element?.moduleName,
              userGroups: element?.assignedUserGroups
            })
          });
        }
      }
    })
    let deletedSubscritions = this.editUserDetails?.subscriptionModules?.filter((item1: any) => !this.subscription?.value?.some((item2: any) => item1?.subscriptionId == item2?.subscriptionId))
    deletedSubscritions.forEach((sub: any) => {
      sub.isAssigned = false;
      sub?.assignedModules?.forEach((module: any) => {
        module.isAssigned = false
        module?.assignedUserGroups?.forEach((userGroup: any) => {
          userGroup.isAssigned = false
        })
      })
    })
    console.log('deletedSubscritions', deletedSubscritions)
    this.subscription?.value?.forEach((element: any) => {
      let modules: any = [];
      let subscription: any = {
        "subscriptionId": element?.subscriptionId,
        "subscriptionName": element?.subscriptionName,
        "isAssigned": element?.isAssigned,
        "assignedModules": []
      }
      element?.modules?.forEach((item: any) => {
        let groups: any = [];
        let module: any = {
          "moduleId": item?.id,
          "moduleName": item?.moduleName,
          "isAssigned": item.isAssigned,
          "assignedUserGroups": []
        }
        item?.userGroups?.forEach((ele: any) => {
          let group: any = {
            "groupId": ele?.groupId ? ele?.groupId : ele?.id,
            "groupName": ele?.groupName ? ele?.groupName : ele?.userGroupName,
            "isAssigned": ele.isAssigned
          }
          if (group) {
            groups.push(group);
          }
        })
        module.assignedUserGroups = groups;
        modules.push(module)
      })
      subscription.assignedModules = modules;
      subscriptions.push(subscription)
    });
    subscriptions = _.cloneDeep([...subscriptions, ...deletedSubscritions])
    return subscriptions;
  }
  resetForm() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Confirmation',
        message: ' This action will reset all the records.Are you sure you want to cancel?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit()
      }
    });

  }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }
  removeGroup(group: any) {
    let index = this.subscription.value[this.activeSubscriptionIndex]?.modules?.findIndex((item: any) => item.id == this.subscription.value[this.activeSubscriptionIndex]?.selectedModule.id);
    (index > -1) && (this.subscription.value[this.activeSubscriptionIndex].modules[index].userGroups = this.subscription?.value[this.activeSubscriptionIndex]?.modules[index]?.userGroups?.filter((item: any) => item.id != group.id));

  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.dropdown-container');
    if (!clickedInside) {
      this.showOtherSelectedGroupsFlag = false;
      this.toggleSubscriptions = false;
      this.toggleModules = false;
    }
  }
  goback() {
    this.router.navigate(['/manage-users']);
  }
  getUserGroup(i: any) {
    return this.subscription?.value[i]?.selectedModule?.userGroups?.filter((item: any) => item.isAssigned != false);
  }


}

