import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmEmailService } from './confirm-email.service';
import { UtilService } from 'src/app/shared/helper/util.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrl: './confirm-email.component.scss'
})
export class ConfirmEmailComponent {
  emailForm: FormGroup;
  verificationForm: FormGroup;
  showVerificationSection = false;
  emailError = "Please Enter valid email"
  showToastMessage = false
  currentDate = ''
  countryName = ''
  operatingSystem = ''
  browserName = ''
  showErrorToast = false;
  error = ''
  successMsg = ''
  showSuccessToast = false
  globalEmail: any = '';
  errMsgText: string = null;
  flagError = false;
  constructor(private fb: FormBuilder, private router: Router, private confirmEmail: ConfirmEmailService,private utilService: UtilService, private spinnerService: NgxSpinnerService) { }
  ngOnInit() {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$')]]
    })
    this.verificationForm = this.fb.group({
      code: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]]
    })
    this.currentDate = this.formatDate();
    // this.getCountryName();
    this.countryName='India' // temporarly set country for testing
    this.operatingSystem = this.getOS();
    this.browserName = this.getBrowserName();
    this.confirmEmail.email$.subscribe(res => {
      this.globalEmail = res;
    })
  }
  sendVerification() {
    // this.showVerificationSection = true;
    let payload = {
      "email": this.emailForm.get('email').value?.toLowerCase()?.trim(),
      "date": this.currentDate,
      "os": this.operatingSystem,
      "browser": this.browserName,
      "location": this.countryName
    }
    // console.log(payload)
    this.confirmEmail.setEmail(this.emailForm.get('email').value?.trim());
    this.confirmEmail.sendEmailVerificationCode(payload).subscribe({
      next:(res: any) => {
        if (res.code == 500) {
          this.error = res.result.error;
          this.showErrorToast = true;
          setTimeout(() => {
            this.showErrorToast = false;
            this.error = ''
          }, 10000)
        }
        else if (res.code == 401) {
          this.error = res.result.error;
          this.showErrorToast = true;
          setTimeout(() => {
            this.showErrorToast = false;
            this.error = ''
          }, 10000)
        }
        else if (res.code == 200) {
          this.successMsg = res.result;
          this.showSuccessToast = true;
          this.showVerificationSection = true;
          setTimeout(() => {
            this.showSuccessToast = false
            this.successMsg = ''
          }, 5000)
        }
      },
      error:(error)=>{
        this.spinnerService.hide()
        this.utilService.SharingDataTextMessage.subscribe((res: any) => {
          this.error = res
        })
        this.showErrorToast = true;        
        setTimeout(() => { this.showErrorToast = false; this.error = '';this.utilService.SharingDataTextMessage.next('') }, 5000)
      }
    }
  )
  }
  formatDate() {
    const options: any = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kolkata',
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const date = new Date();
    const formattedDate = formatter.format(date);
    // Add the timezone information
    return `${formattedDate} (Asia/Kolkata)`;
  }
  getCountryName() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;

        // Fetch country name using a reverse geocoding service
        fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
          .then(response => response.json())
          .then(data => {
            const countryName = data.countryName;
            console.log(`Country: ${countryName}`);
            this.countryName = countryName;
          })
          .catch(error => console.error('Error fetching country name:', error));
      }, error => {
        console.error('Geolocation error:', error);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }
  getOS() {
    const userAgent = navigator.userAgent;

    let os = "Unknown OS";

    if (userAgent.includes("Windows")) {
      os = "Windows";
    } else if (userAgent.includes("Macintosh")) {
      os = "macOS";
    } else if (userAgent.includes("Linux")) {
      os = "Linux";
    } else if (userAgent.includes("Android")) {
      os = "Android";
    } else if (userAgent.includes("like Mac")) {
      os = "iOS";
    }

    return os;
  }
  getBrowserName() {
    const userAgent = navigator.userAgent;
    let browserName = "Unknown Browser";

    if (userAgent.includes("Chrome")) {
      browserName = "Google Chrome";
    } else if (userAgent.includes("Firefox")) {
      browserName = "Mozilla Firefox";
    } else if (userAgent.includes("Safari")) {
      browserName = "Apple Safari";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
      browserName = "Microsoft Internet Explorer";
    } else if (userAgent.includes("Edge")) {
      browserName = "Microsoft Edge";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
      browserName = "Opera";
    }

    return browserName;
  }
  verifyCode() {
    let payload = {
      "email": this.globalEmail,
      "verificationCode": this.verificationForm.get('code').value
    }
    // this.router.navigate(['/create-password'])
    this.confirmEmail.verifyEmailCode(payload).subscribe((res: any) => {
      if (res.code == 200) {
        this.showToastMessage = true;
        this.verificationForm.get('code').patchValue('')
        setTimeout(() => { this.showToastMessage = false;this.showSuccessToast=false; this.router.navigate(['/create-password']) }, 3000)
      } else if (res.code == 500) {
        this.error = res.result.error;
        this.showErrorToast = true;
        setTimeout(() => {
          this.showErrorToast = false;
          this.error = ''
        }, 5000)
      }
    })
  }
  get email() {
    return this.emailForm.get('email') as FormControl;
  }
}
