<div class="analyticsPage">
  <!-- <lib-app-header></lib-app-header> -->
  <div class="wrpr">
    <div class="contentCard">
      <div class="pageHead">
        <img src="../../../../assets/images/analyticsIcon.svg" alt="analytics-icon">
        <h1>Analytics</h1>
      </div>
      <div class="tabContent">
        <form [formGroup]="filterForm">

          <input type="search" [(ngModel)]="searchTerm" class="search inputField" placeholder="Search"
            formControlName="search" (keyup.enter)="filterAnalytics()">
            <button type="button" *ngIf="searchTextFlag" class="clear-btn" (click)="clearSearch()" >&#x2715;</button>
          <div class="filtersCont">
            <div class="sectionHead">
              <img src="../../../../assets/images/filterIcon.svg" alt="filters-icon">
              <h4>Filters</h4>
            </div>
            <div class="filterRow">
              <div class="formControl type">
                <label for="type" class="formLabel">
                  Type
                </label>
                <div class="dropdown">
                  <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="typeDropdownSettings"
                    class="toggleCont2" [data]="typeOptions" formArrayName="typeList"
                    [ngModelOptions]="{ standalone: true }" [(ngModel)]="selectedFilterType"
                    (onSelect)="onTypeSelect($event)" (onDeSelect)="onTypeDeselect($event)"
                    (onSelectAll)="onSelectAllTypes($event)" (onDeSelectAll)="onDeSelectAllTypes()">
                    {{ typeOptions }}
                  </ng-multiselect-dropdown>
                </div>
              </div>
              <div class="formControl">
                <label for="categories" class="formLabel">
                  Filter by Categories
                </label>
                <div class="pillRow">
                  <button type="button" class="pillBtn " *ngFor="let category of allCategories.slice(0,3)"
                    (click)="onCheckboxChange($event, category,true)" [ngClass]="category.selected?'activeTab':''">
                    <p>
                      {{category.categoryName}}
                    </p>
                    <img src="../../../../assets/images/general-glyphs-close.svg" class="close_icon"
                      *ngIf="category.selected">
                  </button>
                  <span class="dropdown-container">
                    <span class="others" *ngIf="allCategories?.slice(3)?.length>0">
                      <span (click)="toggleCategory()" class="MoreLabel">
                        +{{allCategories?.slice(3)?.length}} others
                      </span>
                      <div class="othersList" *ngIf="showCategory">
                        <div class="gap"></div>
                        <div *ngFor="let category of allCategories.slice(3)">
                          <input type="checkbox" class="checkbox" [id]="category.categoryId"
                            (change)="onCheckboxChange($event, category)"
                            [checked]="isCategorySelected(category.categoryName)" />
                          <label [for]="category.categoryId"
                            [title]="category.categoryName">{{category.categoryName}}</label>
                        </div>
                      </div>
                    </span>
                  </span> 
                  <button  type="button" class="filter_reset" (click)="resetCategoryType()"> Reset </button>
                </div>
              </div>
            </div>
            <div class="formControl">
              <label for="categories" class="formLabel">
                Filter by Tags
              </label>
              <div class="pillRow">
                <button type="button" class="pillBtn" *ngFor="let tag of allTags.slice(0,4)"
                  (click)="onTagCheckboxChange($event, tag,true)" [ngClass]="tag.selected?'activeTab':''">
                  <p>{{tag.tagName}}</p>
                  <img src="../../../../assets/images/general-glyphs-close.svg" class="close_icon" *ngIf="tag.selected">
                </button>
                <span class="dropdown-container">
                  <span class="others" *ngIf="allTags?.slice(4)?.length>0">
                    <span (click)="toggleTags()" class="MoreLabel">
                      +{{allTags?.slice(4)?.length}} others
                    </span>
                    <div class="othersList" *ngIf="showTags">
                      <div class="gap"></div>
                      <div *ngFor="let tag of allTags.slice(4)">
                        <input type="checkbox" class="checkbox" [id]="tag.tagId"
                          (change)="onTagCheckboxChange($event, tag)" [checked]="isTagSelected(tag.tagName)" />
                        <label [for]="tag.tagId">{{tag.tagName}}</label>
                      </div>
                    </div>
                  </span>
                </span>
                <button  type="button" class="filter_reset" (click)="resetTab()"> Reset </button>
              </div>
            </div>
          </div>
          <div class="flexSP">
            <h2>{{getinsightsModules.length}} Products</h2>
            <div class="formControl sort">
              <button type="button" class="toggleCont" (click)="showSorting()">
                <p>
                  <span>
                    <img src="../../../../assets/images/sort.svg" alt="">
                  </span>
                  Sort <span *ngIf="selectedSort"> -{{selectedSort}}</span>
                </p>
                <span *ngIf="selectedItems.length" class="optionSelected" title="{{selectedItems.join(', ')}}">{{
                  selectedItems.join(', ')}}</span>
                <img src="../../../../assets/images/chevron-south.svg" alt="chevron-south">
              </button>
              <div class="optionsCont" *ngIf="isSortingOpen">
                <!-- module_name, created_date, updated_date -->
                <button type="button" class="option" *ngFor="let item of sortingList"
                  (click)="sortAnalytics(item)">{{item.label}}</button>
                <!-- <button type="button" class="option" (click)="sortByNameAZ()">Descending</button> -->
              </div>
            </div>
          </div>
          <div class="products">
            <div class="noResults" *ngIf="getinsightsModules?.length === 0">
              <img src="../../../../assets/images/noResults.png" alt="no-results">
              <p>No results found.</p>
            </div>
            <ng-container *ngIf="getinsightsModules?.length > 0">
              <div class="productCard" *ngFor="let analyticsProduct of getinsightsModules">
                <div class="banner" [ngClass]="{'dataSet': analyticsProduct.isDashboard!}">
                  <img src="../../../../assets/images/pbiDashboard.svg" alt="power-bi-dashboards-icon"
                    *ngIf="analyticsProduct.moduleTypeName=='Dashboard'; else datasetIcon">
                  <ng-template #datasetIcon>
                    <img src="../../../../assets/images/pbi-datasets.svg" alt="power-bi-dashboards-icon">
                  </ng-template>
                  <div class="typeTag">{{ analyticsProduct.moduleTypeName=='Dashboard' ? 'Dashboard' :
                    'Dataset' }}</div>
                </div>
                <h2>{{analyticsProduct.moduleName}}</h2>
                <p class="desc">
                  {{analyticsProduct.moduleLongDescription}}
                </p>
                <a class="dmintLink" (click)="openPopup(analyticsProduct)">View More</a>
                <div class="ctaCont">
                  <button type="button" class="secondary" *ngIf="!userId" (click)="onSignIn()">
                    SIGN IN
                    <img src="../../../../assets/images/visitLinkIcon.svg" alt="">
                  </button>
                  <button type="button" class="secondary" *ngIf="analyticsProduct.subscription && userId" (click)="
                  onInsightsApicall(analyticsProduct.id, analyticsProduct.moduleLink)
                ">
                    Visit site
                    <img src="../../../../assets/images/visitLinkIcon.svg" alt="">
                  </button>
                  <button type="button" class="secondary" *ngIf="analyticsProduct.subscription == false && userId" (click)="onSubscribe()">
                    SUBSCRIBE
                  </button>
                </div>
                <div class="addToFav">
                  <img src="../../../../assets/images/favourite.svg" alt="">
                </div>
              </div>
            </ng-container>
          </div>
        </form>

      </div>
    </div>
    <div class="productDetailsPopupCont" *ngIf="openedCard">
      <div class="productDetailsPopup">
        <div class="banner" [ngClass]="{'dataSet': openedCard.isDashboard!}">
          <img src="/assets/images/pbiDashboard.svg" alt="power-bi-dashboards-icon"
          *ngIf="openedCard.moduleTypeName=='Dashboard'; else datasetIcon">
          <ng-template #datasetIcon>
            <img src="/assets/images/pbi-datasets.svg" alt="power-bi-dashboards-icon">
          </ng-template>
          <div class="typeTag">

            {{ openedCard.moduleTypeName=='Dashboard'? 'Dashboard' : 'Dataset' }}
          </div>
        </div>
        <h2>{{openedCard.moduleName}}</h2>
        <p class="desc">
          {{openedCard.moduleLongDescription}}
        </p>
        <div class="ctaCont">
          <a (click)="closeProductPopup()" class="dmintLink">
            Close
          </a>
          <button type="button" class="secondary" *ngIf="!userId" (click)="onSignIn()">
            Sign In
            <img src="/assets/images/visitLinkIcon.svg" alt="">
          </button>
          <button type="button" class="secondary" *ngIf="openedCard.subscription && userId" (click)="
            onInsightsApicall(openedCard.id, openedCard.moduleLink)
          ">
            Visit site
            <img src="/assets/images/visitLinkIcon.svg" alt="">
          </button>
          <button type="button" class="secondary" *ngIf="openedCard.subscription == false && userId">
            SUBSCRIBE
          </button>
        </div>
        <div class="addToFav">
          <img src="/assets/images/favourite.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container>
  <div class="productContainer col-12">
    <div class="productsGrid"></div>
    <div class="modal-popup-container container-fluid" *ngIf="isViewMoreVisible">
      <!-- *ngIf="isViewMoreVisible" -->
      <div class="modal-card col-6">
        <div class="likeBtn true" *ngIf="id">
          <img class="default" src="../../../../assets/images/favourite_Btn_Icon_default.svg"
            alt="like-product-icon-default" />
          <img class="active" src="../../../../assets/images/favourite_Btn_Icon_active.svg"
            alt="like-product-icon-active" />
        </div>

        <div class="productDetails">
          <p class="product-desc-wrapper">

          </p>
        </div>
        <div class="ctaCont col-12">
          <button type="button" class="tertiary_green_link_btn" (click)="onViewMoreClose()">
            Close
          </button>
          <div class="primary_green_btn" *ngIf="!userId">
            <a class="btnText" (click)="onSignIn()">
              Sign In
              <span><img class="smallerIcons" src="../../../../assets/images/btn_icon.svg" alt="signin-site" /></span>
            </a>
          </div>
          <div class="primary_green_btn">

            <div class="btnText" onKeyPress="">
              Visit Site
              <span><img class="smallerIcons" src="../../../../assets/images/btn_icon.svg" alt="visit-site" /></span>
            </div>
          </div>
          <div class="primary_green_btn">
           
            <a class="btnText" (click)="onSubscribe()">
              Subscribe
              <span><img class="smallerIcons" src="../../../../assets/images/btn_icon.svg"
                  alt="subscribe-site" /></span>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
 </ng-container>
<!-- <app-alert [message]="errMsgPopup" *ngIf="showErrorMessageWithPopup" (close)="onCloseHandler()"></app-alert> -->

<app-alert [message]="errMsg" *ngIf="showErrorMessageWithPopup" (close)="onCloseHandlerSub()"></app-alert>