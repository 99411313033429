import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {

  //userIds: any;
  showHeaderMenus = new BehaviorSubject<boolean>(false);

  getPopupStatus = new BehaviorSubject<boolean>(false);
  SharingDataPopUpMessage = new Subject();

  SharingDataTextMessage = new BehaviorSubject<any>('');
  errorTextBlock = new BehaviorSubject<boolean>(false);

  getUserId = new BehaviorSubject<any>('');
  getEmail = new BehaviorSubject<any>('');
  resetPasswordShow = new BehaviorSubject<boolean>(false);
  resetPasswordMessage = new BehaviorSubject<any>('');
  moduleBytes = new BehaviorSubject<any>('');
  allHeaders = new  BehaviorSubject<any>('');
  companyName = new  BehaviorSubject<any>('');
  apiModules =  new BehaviorSubject<any>('');
  apiUserGroups = new BehaviorSubject<any>('');

  constructor(private auth: AuthService,private router:Router) {}

  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  showMenus(value: boolean) {
    this.showHeaderMenus.next(value);
  }

  openPopup(value: boolean) {
    this.getPopupStatus.next(value);
  }

  openErrorTextBlock(value: boolean) {
    this.errorTextBlock.next(value);
  }

  receivEmail(email: any) {
    this.getEmail.next(email);
  }

  resetPasswordVisible(isVisible: any, message: any) {
    this.resetPasswordShow.next(isVisible);
    this.resetPasswordMessage.next(message);
  }

  public get currentPopupValue(): any {
    return this.getPopupStatus.value;
  }

  userId(userIds: any) {
    localStorage.setItem('userId', userIds);
    this.getUserId.next(userIds);
  }

  onSignIn() {
    this.auth.loginWithRedirect({appState: { target:localStorage.getItem('lastVisitedUrl') }});
  }

  setModuleByte(moduleBytesMap: any){
    this.moduleBytes.next(moduleBytesMap);
  }
  
  setAllHeaders(allHeaders: any){
    this.allHeaders.next(allHeaders);
  }

  setUserCompanyName(companyName: any){
    this.companyName.next(companyName);
  }

  setApiModules(apiModules: any) {
   this.apiModules.next(apiModules);
  }

  setApiUserGroups(apiUserGroups: any) {
    this.apiUserGroups.next(apiUserGroups);
  }
  
  
}
