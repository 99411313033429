import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, map, catchError, take } from 'rxjs/operators';
import { CreateUserService } from '../components/manage-users/create-user/create-user.service';

export function emailAsyncEditValidator(createService: CreateUserService,currentEmail$:BehaviorSubject<string>) {
    return (control: AbstractControl): Observable<any> => {
        if (!control.value) {
            return of(null);
        }
        if (currentEmail$.getValue() && currentEmail$.getValue() === control.value) return of(null);
        let val = control.valueChanges.pipe(
            debounceTime(500),
            switchMap(() =>
                createService.checkEmailExists(control.value).pipe(
                    map((response) => {
                        return response ? { emailExists: true } : null;
                    }),
                    catchError(() => of(null))
                )
            ),
            take(1)
        );
        return val;
    };
}
